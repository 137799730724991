




















import { Component, Vue, Prop } from 'vue-property-decorator';
import LineSelectMenuCard from './LineSelectMenuCard.vue';

import { UserModule } from '@/store/modules/user';
import { getUUID } from '@/utils/base';
import { ShopPriceGetTransportLine } from '@/api/select';
@Component({
    name: 'LineSelectCard',
    components: {
        LineSelectMenuCard
    }
})
export default class LineSelectCard extends Vue {
    @Prop({ type: [String], default: '' }) public value: string;
    // small mini
    @Prop({ type: String, default: 'small', validator: (value: string) => ['small', 'mini'].includes(value) }) public size: 'small' | 'mini';

    @Prop({ type: Number, default: 0 }) public lineType: 0 | 1 | 2;

    public id: string = getUUID();

    // 加载中...
    public loading: boolean = false;

    // 是否显示下拉
    public isShow: boolean = false;

    public list: any = [];

    private mounted() {
        this.init();
        document.addEventListener('click', this.clickEventHandler);
    }

    private destroyed() {
        document.removeEventListener('click', this.clickEventHandler);
    }

    private clickEventHandler(event) {
        const targetElement = document.getElementById(`targetElement${this.id}`);
        if (targetElement) {
            const isClickedOutside = !targetElement.contains(event.target);
            if (isClickedOutside) {
                this.isShow = false;
            } else {
                this.isShow = true;
            }
        }
    }

    private async init() {
        try {
            this.loading = true;
            const { Data } = await ShopPriceGetTransportLine({
                lineType: this.lineType
            });
            // Data.push({
            //     CountryIds: '1620|1625',
            //     CountryNames: '日本|中国大陆'
            // });
            this.list = Data;
            if (this.ClientPreference && this.ClientPreference.CountryIds) {
                this.SelectChange(this.ClientPreference);
            } else {
                this.SelectChange(Data[0]);
            }
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    public SelectChange(item) {
        this.$emit('input', item.CountryIds);
        this.$emit('change', item);
    }

    public get TextLable() {
        const value = this.list.find((e) => e.CountryIds === this.value);
        const label: any = {};
        if (value) {
            const row = value.CountryNames.split('|');
            label.key1 = row[0];
            label.key2 = row[1];
        }
        return label;
    }

    private get ClientPreference() {
        return UserModule.userData.ClientPreference;
    }

}
