


























































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'FreightForm',
    components: {}
})
export default class FreightForm extends Vue {
    @Prop({
        type: Object,
        default: {}
    })
    public value: any;

    public chnage() {
        let is = false;
        const arr = ['Length', 'Width', 'Height', 'Weight'];
        for (let i = 0; i < arr.length; i++) {
            const key = arr[i];
            if (this.value[key] < 0) {
                is = true;
            }
        }

        if (is) {
            const message: any = this.$t('standard.form.message1');
            this.$message(message);
            return;
        }

        if (this.value.Weight === '') {
            const message: any = this.$t('standard.form.message');
            this.$message(message);
            return;
        }
        this.$emit('change');
    }
}
