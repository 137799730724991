













































































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'RenderTable',
    components: {}
})
export default class RenderTable extends Vue {
    @Prop({ type: Array, default: [] }) public data: any;

    @Prop({ type: [String, Number], default: [] }) public type: any;

    @Prop({ type: Object, default: () => ({}) }) public value: any;

    // 需要显示的字段
    public showFields: any = 0;

    public title = [
        [],
        [
            { label: this.$t('standard.table2[0]'), prop: 'WareHouseNames', align: 'center' },
            { label: this.$t('standard.table3[1]'), prop: 'CarrierNames', align: 'center' },
            { label: this.$t('standard.table2[1]'), prop: 'FreightFee', align: 'center', scopedSlots: 'FreightFee' },
            { label: this.$t('standard.table2[2]'), prop: 'FreightFeeExpression', align: 'center' }
        ],
        [
            { label: this.$t('standard.table3[0]'), prop: 'WareHouseNames', align: 'center' },
            { label: this.$t('standard.table3[1]'), prop: 'CarrierNames', align: 'center' }
        ]
    ];

    // 表格合并
    public objectSpanMethod(obj) {
        if (this.type === 2) {
            return;
        }

        const flag = {
            columnIndex: 0, // 第一列
            rowIndex: 0 // 第一行
        };

        // 已经换列的话重给标识
        if (obj.rowIndex !== flag.rowIndex) {
            flag.rowIndex = obj.rowIndex;
        }

        // 第n行 第一列
        if (flag.rowIndex === obj.rowIndex && obj.columnIndex === 0) {
            return {
                rowspan: obj.row.rowspan,
                colspan: 1
            };
        }
    }
}
