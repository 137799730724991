
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetCountrySelectBoxByShop } from '@/api/select';

// 报价模块目的地

@Component({
    name: 'ShopSelect',
    components: {}
})
export default class ShopSelect extends Vue {
    @Prop({
        type: [Number, String],
        default: ''
    })
    public value!:any;

    public options: Array<any> = [];

    private mounted() {
        this.init();
    }

    private async init() {
        try {
            const params: any = {};
            const { Data } = await GetCountrySelectBoxByShop(params);
            this.options = Data;
            this.$emit('loaded');
            this.handleChange(Data[0].Value);
        } catch (err) {
            console.warn(err);
            this.handleChange(null);
        }
    }

    public handleChange(val: any) {
        this.$emit('input', val);
        let name: any = '';
        this.options.forEach((item) => {
            if (item.Value === val) {
                name = item.Text;
            }
        });
        this.$emit('inputName', name);
    }
}
