
















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'LineSelectMenuCard',
    components: {}
})
export default class LineSelectMenuCard extends Vue {
    @Prop({ type: [Number, String], default: '' }) public value: number | string;
    @Prop({ type: [Array], default: [] }) public list;
    // small mini
    // @Prop({ type: String['small', 'mini'], default: 'small' }) public size: string;
    @Prop({ type: String, default: 'small', validator: (value: string) => ['small', 'mini'].includes(value) }) public size: 'small' | 'mini';

    public change(item) {
        this.$emit('change', item);
    }
}
