







import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'YwhzLayoutCard',
    components: {}
})
export default class YwhzLayoutCard extends Vue {
    @Prop({
        type: String,
        default: ''
    })
    public title: string;

    @Prop({
        type: String,
        default: ''
    })
    public icon: string;

    private mounted() {
        // aa
    }
}
