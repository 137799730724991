








import { Component, Vue } from 'vue-property-decorator';

import YwhzThirdParty from './third-party.vue';

@Component({
    name: 'ChargeStandard',
    components: {
        YwhzThirdParty
    }
})
export default class ChargeStandard extends Vue {

}
